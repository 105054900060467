.my-table {
  border-collapse: collapse;
  width: 100%;
}

.my-table th,
.my-table td {
  padding: 8px;
  text-align: left;
}

/*!* style first column *!*/
/*.my-table tr td:first-child,*/
/*.my-table tr th:first-child {*/
/*  background-color: #e6f7ff;*/
/*}*/


/*.my-table tr td:nth-child(2),*/
/*.my-table tr th:nth-child(2) {*/
/*  background-color: #b3e0ff;*/
/*}*/

/*!* style third column *!*/
/*.my-table tr td:nth-child(3),*/
/*.my-table tr th:nth-child(3) {*/
/*  background-color: #c7dec1;*/
/*}*/

/*.my-table tr td:nth-child(4),*/
/*.my-table tr th:nth-child(4) {*/
/*  background-color: #b1d2a7;*/
/*}*/


/*.my-table tr td:nth-child(5),*/
/*.my-table tr th:nth-child(5) {*/
/*  background-color: #f1dddd;*/
/*}*/
/*.my-table tr td:nth-child(6),*/
/*.my-table tr th:nth-child(6) {*/
/*  background-color: #d3a7a7;*/
/*}*/

.blur-container {
  filter: blur(8px); /* Adjust the blur value as needed */
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  display: true;
}
/* EsmDataTableWithBlur.css */

.card_main_info{
  background-color: #a6c782;

}

